import React, { lazy, Suspense } from "react";
import AppLoading from "../resources/components/loaders/AppLoading/AppLoading";

const App = lazy(() => import("../App"));

function Screen() {
    return (
        <div id="Screen">
            <div id="top" />
            <Suspense fallback={<AppLoading />}>
                <App />
            </Suspense>
            <div id="bottom" />
        </div>
    );
}

export default Screen;
