import React, { memo } from 'react'
import './AppLoading.scss'
import {AppName} from "../../../../config/Config";
import logoSmall from "../../../../views/layout/Sider/CollapsedMenu/logo-icon.svg";

const AppLoading = () =>
{
    document.title = `Loading ` + AppName
    return (

        <div className="AppLoading">

            <img src={ logoSmall } alt="HDL Logo" />

            <div className="text">H D L</div>

        </div>
    );
}

export default memo( AppLoading )